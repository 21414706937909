body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}
h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media(min-width:1200px) {
  h1 {
  font-size: 2.5rem;
}
}h2 {
  font-size: calc(1.325rem + .9vw);
}
@media(min-width:1200px) {
  h2 {
  font-size: 2rem;
}
}h3 {
  font-size: calc(1.3rem + .6vw);
}
@media(min-width:1200px) {
  h3 {
  font-size: 1.75rem;
}
}h4 {
  font-size: calc(1.275rem + .3vw);
}
@media(min-width:1200px) {
  h4 {
  font-size: 1.5rem;
}
}h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}

.countdown {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 2rem;
  text-align: center;
}

.countdown::before,
.countdown::after {
  content: "";
  width: 1ch;
  animation: countdown 100s step-end infinite;
}

.countdown::after {
  animation-duration: 10s;
}

@keyframes countdown {
  0% {
    content: "9";
  }
  10% {
    content: "8";
  }
  20% {
    content: "7";
  }
  30% {
    content: "6";
  }
  40% {
    content: "5";
  }
  50% {
    content: "4";
  }
  60% {
    content: "3";
  }
  70% {
    content: "2";
  }
  80% {
    content: "1";
  }
  90% {
    content: "0";
  }
  100% {
    content: "0";
  }
}

.rotating {
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from{
      -webkit-transform: rotate(0deg);
  }
  to{
      -webkit-transform: rotate(360deg);
  }
}

